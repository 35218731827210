import React from 'react'
import { WalletPolicy } from '../../ledger-bitcoin'
import { EditorProps, formatPublicKey, isValidPublicKey, Policy, PolicyTemplate, PublicKey, ValidationContext } from "../policy"
import { updateArray, updateArraySize } from '../../components/update'
import { kEmptyPublicKey } from '../constants'
import { Col, Form, Row, Tab, Tabs } from '../../components/ui'
import PublicKeyControl from '../../components/PublicKeyControl'

export interface MultisigPolicy extends Policy {
  readonly threshold: number
}

function createWalletPolicy(policy: MultisigPolicy) {
  const keys = policy.publicKeys.map(formatPublicKey)
  let p = ''
  for (let index = 0; index < policy.publicKeys.length; index++) {
    p += ',@' + index
  }
  return new WalletPolicy(
    policy.name,
    `wsh(sortedmulti(${policy.threshold}${p}))`,
    keys
  )
}

/**
 * Editor
 */
function Editor({ value, onChange }: EditorProps<MultisigPolicy>) {
  const setPublicKey = React.useCallback((index: number, publicKey: PublicKey) => onChange({ ...value, publicKeys: updateArray(value.publicKeys, index, publicKey) }), [onChange, value])
  const [key, setKey] = React.useState(0)

  return (<React.Fragment>
    <Row>
      <Form.Group as={Col} className="mb-3">
        <Form.Label>Threshold</Form.Label>
        <Form.Control id="threshold" type="number"
          isInvalid={!isValidThreshold(value)}
          value={value.threshold}
          onChange={e => onChange({ ...value, registration: undefined, threshold: parseInt(e.target.value) })} />
        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} className="mb-3">
        <Form.Label>Number of co-signers</Form.Label>
        <Form.Control id="cosigners" type="number"
          isInvalid={!isValidCosignerCount(value)}
          value={value.publicKeys.length}
          onChange={e => onChange({ ...value, publicKeys: updateArraySize(value.publicKeys, parseInt(e.target.value), kEmptyPublicKey) })} />
        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
      </Form.Group>
    </Row>

    <Tabs id="publicKeys" activeKey={key} onSelect={k => setKey(parseInt(k!))} className="mb-3">
      {value.publicKeys.map((publicKey, index) =>
        <Tab key={index} eventKey={index} title={`Key ${(index + 1)} - ${publicKey.name}`}>
          {/* <PublicKeyToolbar
            keyId={vault.getSharedKeyId(policyId, index)}
            value={publicKey}
            onChange={value => setPublicKey(index, value)}
            onRemove={() => removeKey(index)}
          /> */}

          {/* Public Key */}
          <PublicKeyControl
            value={publicKey}
            onChange={k => setPublicKey(index, k)} />
        </Tab>)}
    </Tabs>
    <br />
  </React.Fragment >)
}

function isMultisigPolicy(value: any): value is MultisigPolicy {
  return typeof value === 'object' &&
    typeof (value as MultisigPolicy).threshold === 'number' &&
    typeof (value as MultisigPolicy).publicKeys === 'object' &&
    typeof (value as MultisigPolicy).publicKeys.length === 'number' &&
    true
}

function validate(policy: Policy, context: ValidationContext) {
  if (!isMultisigPolicy(policy)) {
    context.addError('Not a multisig policy')
    return false
  }

  if (!isValidThreshold(policy))
    context.addError('Invalid threshold')
  if (!isValidCosignerCount(policy))
    context.addError('Invalid number of keys')

  for (let index = 0; index < policy.publicKeys.length; index++) {
    const publicKey = policy.publicKeys[index]
    if (!isValidPublicKey(publicKey)) {
      context.addError(`Invalid public key ${index}, ${publicKey.name}`)
      continue
    }

    const dup = policy.publicKeys.find(p => p.masterFingerprint !== '' && p !== publicKey && p.masterFingerprint === publicKey.masterFingerprint)
    if (dup != null) {
      context.addError(`Cannot have 2 keys with same fingerprints ${publicKey.name} & ${dup.name} `)
      break
    }
  }

  return context.isValid
}

const template: PolicyTemplate<MultisigPolicy> = {
  id: 'multisig',
  name: 'Multisig m of n',
  create: policy => ({ ...policy, name: 'Cold Storage', threshold: 2, publicKeys: [kEmptyPublicKey, kEmptyPublicKey] }),
  createWalletPolicy,
  editor: Editor,
  validate,
}

export default template

function isValidThreshold(value: MultisigPolicy) {
  return value.threshold >= 1 && value.threshold <= value.publicKeys.length
}

function isValidCosignerCount(value: MultisigPolicy) {
  return value.publicKeys.length >= 2 && value.publicKeys.length >= value.threshold
}

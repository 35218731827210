import { createRoot } from 'react-dom/client'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom"
import React from 'react'
import { LedgerProvider } from './lib/ledger'
import { VaultProvider } from './lib/vault'
import { PolicyManagerProvider } from './lib/policyStore'
import { DialogProvider } from './lib/dialog'
import { PwaProvider } from './lib/pwa'
import { PublicKeyStoreProvider } from './lib/publicKeyStore'
import MainPage from './pages/MainPage'
import PolicyStorePage from './pages/PolicyStorePage'
import PolicyPage from './pages/PolicyPage'
import PolicyEditorPage from './pages/PolicyEditorPage'
import SharePublicKeyPage from './pages/SharePublicKeyPage'
import PublicKeyStorePage from './pages/PublicKeyStorePage'
import PolicyGeneralPage from './pages/PolicyGeneralPage'
import PublicKeyPage from './pages/PublicKeyPage'
import PublicKeyEditorPage from './pages/PublicKeyEditorPage'
import PublicKeyGeneralPage from './pages/PublicKeyGeneralPage'

createRoot(document.getElementById('app')!).render(<LegderApp />)

if (process.env.VAULT_RUN_TESTS) {
  require('./tests')
}

function LegderApp() {
  return (
    <div className="text-xs-device page-wrapper">
      <DialogProvider>
        <PwaProvider>
          <BrowserRouter>
            <VaultProvider>
              <LedgerProvider>
                <PublicKeyStoreProvider>
                  <PolicyManagerProvider>
                    <Routes>
                      <Route path="/" element={<MainPage />} >
                        <Route path="policies" element={<PolicyStorePage />} />
                        <Route path="policies/:key" element={<PolicyPage />}>
                          <Route index={true} element={<PolicyGeneralPage />} />
                          <Route path="edit" element={<PolicyEditorPage />} />
                        </Route>
                        
                        <Route path="keys" element={<PublicKeyStorePage />} />
                        <Route path="keys/:key" element={<PublicKeyPage />} >
                          <Route index={true} element={<PublicKeyGeneralPage />} />
                          <Route path="edit" element={<PublicKeyEditorPage />} />
                        </Route>
                      </Route>
                      <Route path="/share/key/:id" element={<SharePublicKeyPage />} />
                    </Routes>
                  </PolicyManagerProvider>
                </PublicKeyStoreProvider>
              </LedgerProvider>
            </VaultProvider>
          </BrowserRouter>
        </PwaProvider>
      </DialogProvider>
    </div >)
}


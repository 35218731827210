import React from 'react'
import PublicKeyView from "../components/PublicKeyView"
import { usePublicKeyStore } from '../lib/publicKeyStore'

export default function PublicKeyGeneralPage() {
  const store = usePublicKeyStore()
  const value = store.getByKey()

  return (
    <React.Fragment>
      <PublicKeyView value={value} />
    </React.Fragment>)
}


import useAsync from "./useAsync";
import { useLedger } from "../lib/ledger";
import { EditorProps, Policy } from "../lib/policy";

export default function useRegisterPolicy(props: EditorProps<Policy>) {
  const policy = props.value;
  const ledger = useLedger();
  const registerPolicy = useAsync(async () => {
    const registration = await ledger.registerPolicy({ policy });
    props.onChange({ ...policy, registration });
  }, [policy]);

  return registerPolicy;
}

import { kEmptyPublicKey } from './constants'
import { getRandomUUID } from './crypto'
import { PublicKey } from './policy'
import { makeStore } from './store'

const publicKeyStore = makeStore<PublicKey>({
  name: 'keys',
  itemFactory: () => ({ ...kEmptyPublicKey, id: getRandomUUID(), name: 'New Key' }),
  itemComparer: comparePublicKeys,
  itemKeyProvider: k => k.id,
})

export const PublicKeyStoreProvider = publicKeyStore.provider
export const usePublicKeyStore = publicKeyStore.useStore

function comparePublicKeys(k1: PublicKey, k2: PublicKey): number {
  let r = 0
  if (k1.id && k2.id && (r = k1.id.localeCompare(k2.id)))
    return r
  if (k1.name && k2.name && (r = k1.name.localeCompare(k2.name)))
    return r
  if (k1.masterFingerprint && k2.masterFingerprint && (r = k1.masterFingerprint.localeCompare(k2.masterFingerprint)))
    return r
  if (k1.derivationPath && k2.derivationPath && (r = k1.derivationPath.localeCompare(k2.derivationPath)))
    return r
  if (k1.xpub && k2.xpub && (r = k1.xpub.localeCompare(k2.xpub)))
    return r
  return r
}

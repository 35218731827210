import React from 'react';
import { Col, Container, Row } from './ui';
import { PublicKey } from '../lib/policy';
import { getMessageSample } from '../lib/crypto';

export default function PublicKeyView({ value }: { value: PublicKey; }) {
  return (<Container>
    <Row>
      <Col>Master fingerprint</Col>
      <Col className="fw-bold">{value.masterFingerprint}</Col>
    </Row>
    <Row>
      <Col>Derivation Path</Col>
      <Col className="fw-bold">{value.derivationPath}</Col>
    </Row>
    <Row>
      <Col>xpub</Col>
      <Col className="fw-bold">{getMessageSample(value.xpub, 12)}</Col>
    </Row>
  </Container>);
}

import React from 'react';
import useAsync from '../hooks/useAsync';
import { Button, ListGroup } from './ui';
import { Address, useLedger } from '../lib/ledger';
import { Policy } from "../lib/policy";

export default function AddressList({ policy }: {
  policy: Policy;
}) {
  const [addresses, setAddresses] = React.useState<Address[]>([])
  const ledger = useLedger();

  const generateAddress = useAsync(async () => {
    const index = addresses.length
    const display = false
    const address = await ledger.getAddress({ policy, index: index, isChange: false, address: '' }, display)
    setAddresses(s => [...s, address])
  }, [addresses]);

  return <React.Fragment>
    <Button
      disabled={generateAddress.isPending}
      onClick={generateAddress.run}>Generate Address</Button>

    <ListGroup variant="flush">
      {addresses.map(a => <ListGroup.Item key={a.address}><AddressView address={a} /></ListGroup.Item>)}
    </ListGroup>
  </React.Fragment>;
}

export function AddressView({ address }: { address: Address }) {
  const ledger = useLedger()
  const verify = useAsync(async () => {
    const display = true
    await ledger.getAddress(address, display)
  }, [address])

  return <span>
    <Button
      className='me-1'
      disabled={verify.isPending}
      onClick={verify.run}>Verify</Button>
    {address.index}: {address.address}
  </span>
}

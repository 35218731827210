import { sha256 } from 'bitcoinjs-lib/src/crypto';
import { ripemd160 } from 'bitcoinjs-lib/src/crypto'
import { getXpubComponents, pathStringToArray } from '../ledger-bitcoin/lib/bip32';

export { ripemd160, sha256 }

export function getRandomUUID(): string {
  return self.crypto.randomUUID()
}

export function getRandomBytes(length: number): Uint8Array {
  const array = new Uint8Array(length);
  return self.crypto.getRandomValues(array);
}

/**
 * Get hash of the specified message
 * @param message UTF-8 string
 * @returns Hex hash
 */
export function getMessageHash(message: string): string {
  const messageSha = sha256(Buffer.from(message, 'utf8'))
  return messageSha.toString('hex')
}

/**
 * Get a short represention of the specified message without the middle characters.
 * This is for display ONLY.
 * @param message
 * @param n Number of characters to show and the beginning and end of the message
 * @returns Short version of the message
 */
export function getMessageSample(message: string, n: number = 6): string {
  const start = message.substring(0, n)
  const end = message.substring(message.length - n)
  return start + '...' + end
}

export function parseDerivationPath(value: string) {
  return pathStringToArray(value)
}

export function parseXpub(value: string) {
  return getXpubComponents(value)
}
import React from 'react'
import { Button, Col, ListGroup, Row } from '../components/ui'
import { Policy } from '../lib/policy'
import { getPolicyTemplate, usePolicyStore } from '../lib/policyStore'

export default function PolicyStorePage() {
  const store = usePolicyStore()

  return (
    <React.Fragment>
      <Button
        className="me-1"
        onClick={() => store.new()}>New</Button>

      {/* <Button
        className="me-1"
        variant="secondary"
        onClick={() => policyManager.importPolicy().then(policy => policyManager.savePolicy(policy))}>Import</Button> */}

      <ListGroup>
        {store.items.map(policy =>
          <ListGroup.Item key={policy.id} action
            onClick={() => store.open(policy)}>
            {renderPolicy(policy)}
          </ListGroup.Item>)}
      </ListGroup>
    </React.Fragment >)
}

function renderPolicy(policy: Policy) {
  const template = getPolicyTemplate(policy.templateId)
  return (<Row>
    <Col>{policy.name}</Col>
    <Col>{template.name}</Col>
    <Col>{policy.publicKeys.map(k =>k.name).join(", ")}</Col>
  </Row>)
}


import { WalletPolicy } from '../ledger-bitcoin'
import { parseDerivationPath, parseXpub } from './crypto'

export type PublicKey = {
    readonly id: string
    readonly name: string
    readonly masterFingerprint: string
    readonly derivationPath: string
    readonly xpub: string
}

export type PublicKeyCore = {
    readonly masterFingerprint: string
    readonly derivationPath: string
    readonly xpub: string
}

export type Timelock = string

export interface Policy {
    readonly id: string
    readonly name: string
    readonly templateId: string
    readonly publicKeys: ReadonlyArray<PublicKey>
    readonly registration?: PolicyRegistration
}

export type PolicyRegistration = {
    readonly name: string
    readonly descriptorTemplate: string
    readonly keys: ReadonlyArray<string>
    readonly policyId: string
    readonly policyHmac: string
}

export interface PolicyTemplate<T extends Policy> {
    readonly id: string
    readonly name: string
    create(policy: Policy): T
    createWalletPolicy(policy: T): WalletPolicy
    editor: (props: EditorProps<T>) => React.ReactElement<EditorProps<T>>
    validate: (policy: Policy, context: ValidationContext) => boolean
}

export class ValidationContext {
    private _errors: Error[] = []

    get isValid() { return this._errors.length === 0 }
    get isInvalid() { return this._errors.length > 0 }

    get errors(): ReadonlyArray<Error> { return this._errors }

    addError(error: string | Error) {
        if (error instanceof Error)
            this._errors.push(error)
        else if (typeof error === 'string')
            this._errors.push(new Error(error))
        else
            throw new Error('unknown type')
    }

    check(fn: Function) {
        try {
            fn()
            return true
        } catch (error: any) {
            this.addError(error)
            return false
        }
    }
}

export interface EditorProps<T> {
    value: Readonly<T>
    onChange: (value: Readonly<T>) => void
}

export function formatPublicKey(keyInfo: PublicKey): string {
    const { masterFingerprint: fingerprint, derivationPath: path, xpub } = keyInfo
    return `[${fingerprint}/${path}]${xpub}/**`
}

// const tests = [
//     "[33f68486/48'/0'/0'/2']xpub6E1S8BqhCVZ6LKqNeKA8bLpJzonRs3cd5YsWbsgb6cAR1PTK7pdpRm413Lm8UPBNvBAUGVsBQScCUyA1YZZRgwzdFFLw4sxKa9xWi4TVUaD/**",
//     // "[/48'/0'/0'/2']/**"
// ]
// tests.forEach(parsePublicKey)

export function parsePublicKeyCore(value: string): PublicKeyCore {
    const kPublicKeyRegex = /\[(?<masterFingerprint>[0-9a-f]+)\/(?<derivationPath>.*)\](?<xpub>xpub.*)\/\*\*/
    const m = kPublicKeyRegex.exec(value)
    if (m == null || m.groups == null)
        throw new Error(`Invalid Public Key format: ${value}`)
    return { xpub: m.groups['xpub'], masterFingerprint: m.groups['masterFingerprint']!, derivationPath: m.groups['derivationPath'], }
}

export function isPolicy(value: any): value is Policy {
    return typeof value === 'object' &&
        typeof (value as Policy).id === 'string' &&
        typeof (value as Policy).templateId === 'string' &&
        typeof (value as Policy).name === 'string' &&
        true
}

// TODO: Improve this check
export function isValidPublicKey(publicKey: PublicKey): boolean {
    return typeof (publicKey) === 'object' &&
        isValidDerivationPath(publicKey.derivationPath) &&
        isValidMasterFingerprint(publicKey.masterFingerprint) &&
        isValidPubKey(publicKey.xpub)
}

export function isValidMasterFingerprint(value: string): boolean {
    return typeof value === 'string' && /[A-Za-f0-9]{8}/.test(value)
}

// https://bips.dev/48/
export function isValidDerivationPath(value: string): boolean {
    try {
        let segments: readonly number[] = []
        return typeof value === 'string' && !!(segments = parseDerivationPath(value)) &&
            (segments[0] & ~0x80000000) == 48 &&
            (segments[1] & ~0x80000000) == 0 && // 0: Mainnet
            isValidAccount(segments[2] & ~0x80000000) && // Account
            (segments[3] & ~0x80000000) == 2 // p2wsh
    } catch {
        return false
    }
}

export function isValidAccount(value: number): boolean {
    return value >= 0 && value < 1000
}

export function isValidPubKey(value: string): boolean {
    try {
        return typeof value === 'string' && value.length > 0 && !!parseXpub(value)
    } catch {
        return false
    }
}

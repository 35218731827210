import React from 'react'
import { NavLink, Outlet } from "react-router-dom"
import { Nav, Navbar } from '../components/ui'
import { usePublicKeyStore } from '../lib/publicKeyStore'

export default function PublicKeyPage() {
  const store = usePublicKeyStore()
  const value = store.getByKey()

  return (
    <React.Fragment>

      <Navbar className="p-2">
        <Navbar.Brand as={NavLink} to="" className="text-primary">{value.name}</Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link as={NavLink} to="edit">Edit</Nav.Link>
        </Nav>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <Nav.Link as={NavLink} to="/keys">Close</Nav.Link>
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>

      <Outlet />

    </React.Fragment>)
}


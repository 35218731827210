import React from 'react'
import { isPolicy, Policy, PolicyTemplate } from "./policy"
import multisig from './policies/multisig'
import multisigTimelock from './policies/multisig-timelock'
import { DialogProps, useDialog } from './dialog'
import { Button, Form } from '../components/ui'
import { makeStore } from './store'
import { getRandomUUID } from './crypto'

const store = makeStore<Policy>({
  name: 'policies',
  itemFactory: () => {
    const template = getDefaultPolicyTemplate()
    const item = template.create({ id: getRandomUUID(), name: template.name, templateId: template.id, publicKeys: [] })
    return item
  },
  itemKeyProvider: x => x.id,
  itemComparer: (x, y) => x.id.localeCompare(y.id),
})

export const PolicyManagerProvider = store.provider
export const usePolicyStore = store.useStore

const kTemplates: PolicyTemplate<Policy>[] = [
  multisig as any, // TODO: remove any
  multisigTimelock,
]

export function getPolicyTemplates(): ReadonlyArray<PolicyTemplate<Policy>> {
  return kTemplates
}

export function getPolicyTemplate(templateId: string): PolicyTemplate<Policy> {
  const template = kTemplates.find(t => t.id === templateId)
  if (!template)
    throw new Error(`cannot find policy template ${templateId}`)
  return template
}

export function getDefaultPolicyTemplate(): PolicyTemplate<any> {
  return kTemplates[0]
}

export function exportPolicyDialog(policy: Policy): DialogProps {
  const json = JSON.stringify(policy, null, '  ')
  const title = `Export policy ${policy.name}`
  const body = <Body />

  return { title, body }

  function Body() {
    const clipboard = useClipboard()
    return <>
      <Form.Control as="textarea" rows={25} readOnly
        value={json}
        onClick={() => clipboard.writeText(json)} />
    </>
  }
}

export function importPolicyDialog({ onChange }: { onChange: (policy: Policy) => void }): DialogProps {
  const title = `Import policy`
  const body = <Body />

  return { title, body }

  function Body() {
    const dialog = useDialog()
    const clipboard = useClipboard()
    const [value, setValue] = React.useState<string>('')
    const [policy, setPolicy] = React.useState<Policy | null>()
    const disabled = !isPolicy(policy)

    return <>
      <Button onClick={() => clipboard.readText().then(setValue)}>Paste from clipboard</Button>
      <Form.Group className="mb-3" controlId="policyJson">
        <Form.Label>Policy JSON</Form.Label>
        <Form.Control as="textarea" rows={25} value={value} onChange={e => {
          try {

            const p = JSON.parse(e.target.value)
            setValue(JSON.stringify(p, null, '  '))
            setPolicy(p)
          } catch {
            setValue(e.target.value)
          }
        }} />
      </Form.Group>
      <Button
        disabled={disabled}
        onClick={() => {
          onChange(policy!)
          dialog.close()
        }}>OK</Button>
    </>
  }
}

function useClipboard() {
  function writeText(content: string) {
    navigator.clipboard.writeText(content)
      .then(() => { console.log('copied', content) })
      .catch(e => alert('could not copy: ' + e))
  }
  function readText() {
    return navigator.clipboard.readText()
  }

  return React.useMemo(() => ({ writeText, readText }), [])
}


import React from 'react'

// TODO: redo this function
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function useAsync<T>(fn: () => Promise<T>, _dependencies?: React.DependencyList): AsyncAction<T> {
  const run = async () => {
    if (state.isPending) throw new Error('shit')
    setState({ hasValue: false, isPending: true, run })
    try {
      const value = await fn()
      setState({ hasValue: true, isPending: false, run, value })
      return value
    } catch (error: any) {
      setState({ hasValue: false, isPending: false, run, error })
      return null
    }
  }

  const [state, setState] = React.useState<AsyncAction<T>>({ hasValue: false, isPending: false, run })

  return { ...state, run }
}

export type AsyncAction<T> = {
  run: () => Promise<T | null>
  hasValue: boolean
  isPending: boolean
  value?: T
  error?: Error
}
export function updateArray<T>(array: ReadonlyArray<T>, index: number, value?: T): ReadonlyArray<T> {
  const result = [...array]
  if (index < 0)
    index = array.length
  if (value === undefined)
    result.splice(index, 1)
  else
    result[index] = value
  return result
}

export function updateArraySize<T>(array: ReadonlyArray<T>, size: number, defaultValue: T): ReadonlyArray<T> {
  if (size < array.length)
    return array.slice(0, size)
  const newArray = [...array]
  for (let i = array.length; i < size; i++)
    newArray.push(defaultValue)
  return newArray
}

import React from 'react'
import { Button } from '../components/ui'
import { usePublicKeyStore } from '../lib/publicKeyStore'
import PublicKeyControl from '../components/PublicKeyControl'

export default function PublicKeyEditorPage() {
  const store = usePublicKeyStore()
  const value = store.getByKey()

  return (
    <React.Fragment>
      <>
        <Button
          className="me-1"
          onClick={() => store.save(value)} >Save</Button>

        {/* <Button
          className="me-1"
          variant="secondary"
          onClick={() => policyManager.exportPolicy(policy)}>Export</Button> */}

      </>

      <hr />

      <h3>Public Key <small className="text-muted">{value.name}</small></h3>

      <PublicKeyControl
        value={value}
        onChange={value => store.saveDraft(value)} />
      <hr />

    </React.Fragment>)
}


import React from 'react'
import { DialogProps } from './dialog'
import { Col, Row } from '../components/ui'
import { AutoCloseNotice } from './ledger'
import { WalletPolicy } from '../ledger-bitcoin'
import { getMessageHash, getMessageSample } from './crypto'

export function confirmPublicKey(derivationPath: string): DialogProps {
    const title = 'Export Public Key'
    const body = (<>
        <Row>
            <Col>Derivation Path</Col>
            <Col>{derivationPath}</Col>
        </Row>
        <Row>
            <Col>Check your device for confirmation</Col>
        </Row>
        <AutoCloseNotice />
    </>)
    return { title, body }
}

/**
 * 
*/
export function registerPolicy(walletPolicy: WalletPolicy): DialogProps {
    const title = <>Register wallet <i>{walletPolicy.name}</i></>
    const body = <>
        <h6>Policy map:</h6>
        <p> {walletPolicy.descriptorTemplate}</p>
        <ul>
            {walletPolicy.keys.map((key, index) =>
                <li key={index} className="text-wrap">
                    {`Key @${index}: ${key}`}
                </li>)}
        </ul>
        <AutoCloseNotice />
    </>
    return { title, body }
}

export function signMessage(derivationPath: string, message: string): DialogProps {
    return {
        title: 'Sign on your device',
        body: <>
            <Row>
                <Col>Verify the following information on your device and sign</Col>
            </Row>
            <Row>
                <Col>Derivation Path</Col>
                <Col>{derivationPath}</Col>
            </Row>
            <Row>
                <Col>Message</Col>
                <Col>{getMessageSample(getMessageHash(message)).toUpperCase()}</Col>
            </Row>
            <AutoCloseNotice />
        </>
    }
}

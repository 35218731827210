import { PublicKey } from './policy';

const kDefaultDerivationPath = `48'/0'/0'/2'`

const kEmptyPublicKey: PublicKey = {
  masterFingerprint: '',
  xpub: '',
  derivationPath: kDefaultDerivationPath,
}

export { kEmptyPublicKey, kDefaultDerivationPath }

import React from 'react';
import { Button, Modal } from '../components/ui';

const Context = React.createContext<Dialog>(null!)

export function useDialog(): Dialog {
    const context = React.useContext(Context)
    if (context === null) { throw new Error('Context missing') }
    return context
}

export function DialogProvider({ children }: React.PropsWithChildren<unknown>) {
    const [dialogProps, setDialogProps] = React.useState<DialogProps | null>(null)
    const context = React.useMemo<Dialog>(() => new Dialog(setDialogProps), [setDialogProps])
    return (<Context.Provider value={context}>
        {dialogProps && <DefaultDialog {...dialogProps} />}
        {children}
    </Context.Provider>)
}

export class Dialog {
    constructor(private _setProps: React.Dispatch<React.SetStateAction<DialogProps | null>>) {
    }

    close() {
        this._setProps(null)
    }

    open(props: DialogProps) {
        this._setProps(props)
    }

    open2(props: {
        title?: React.ReactNode
        body?: React.ReactNode
    }) {
        const footer = <Button onClick={() => this.close()}>Close</Button>
        this._setProps({ ...props, footer })
    }

    openError({ message }: { message: string }) {
        const title = 'Error'
        const body = message
        const footer = <Button onClick={() => this.close()}>Close</Button>
        this._setProps({ title, body, footer })
    }
}

export type DialogProps = {
    title?: React.ReactNode
    body?: React.ReactNode
    footer?: React.ReactNode
}

function DefaultDialog({ title, body, footer }: DialogProps) {
    return (
        <Modal show={true} className='bg-dark ' centered contentClassName='border-0'>
            <Modal.Dialog className='bg-dark'  style={{width:'500px'}}>
                {title && <Modal.Header className='bg-dark'>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>}

                {body && <Modal.Body className='bg-dark'>{body}</Modal.Body>}

                {footer && <Modal.Footer className='bg-dark'>{footer}</Modal.Footer>}
            </Modal.Dialog>
        </Modal>
    );
}


import React from 'react'
import { Button, Col, ListGroup, Row } from '../components/ui'
import { isValidPublicKey } from '../lib/policy'
import { usePublicKeyStore } from '../lib/publicKeyStore'

export default function PublicKeyStorePage() {
  const store = usePublicKeyStore()

  return (
    <React.Fragment>
      <Button
        className="me-1"
        onClick={() => store.new()}>New</Button>

      <ListGroup>
        {store.items.map(key =>
          <ListGroup.Item key={key.id} action
            onClick={() => store.open(key)}>
            <Row>
              <Col>{key.name}</Col>
              <Col>{key.masterFingerprint}</Col>
              <Col>{key.derivationPath}</Col>
              <Col>{isValidPublicKey(key) ? "" : "Invalid"}</Col>
            </Row>
          </ListGroup.Item>)}
      </ListGroup>

    </React.Fragment>)
}


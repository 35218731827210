import React from 'react'
import { Alert, Button } from '../components/ui'
import AddressList from "../components/AddressList"
import { getPolicyTemplate, usePolicyStore } from "../lib/policyStore"
import useRegisterPolicy from "../hooks/useRegisterPolicy"
import PublicKeyView from "../components/PublicKeyView"

export default function PolicyGeneralPage() {
  const policyManager = usePolicyStore()
  const policy = policyManager.getByKey()
  const template = getPolicyTemplate(policy.templateId)
  const registerPolicy = useRegisterPolicy({ value: policy, onChange: p => policyManager.save(p) })

  return (
    <React.Fragment>

      <h3>Policy Descriptor</h3>

      <pre>
        {template.createWalletPolicy(policy).descriptorTemplate}
      </pre>

      <hr />

      <h3>Keys</h3>

      {policy.publicKeys.map((key, index) =>
        <React.Fragment key={index}>

          <h4>{key.name}</h4>

          <PublicKeyView value={key} />

        </React.Fragment>)}

      {!policy.registration && <Alert>
        The Policy has not been registered
        <Button
          className="me-1"
          disabled={registerPolicy.isPending}
          variant="secondary"
          onClick={registerPolicy.run}>Register Now</Button>
      </Alert>}

      <hr />

      {policy.registration && <>
        <h3>Addresses</h3>
        <AddressList policy={policy} />
      </>}
    </React.Fragment>)
}


import React from 'react';
import { Button } from '../components/ui';
import AddressList from "../components/AddressList";
import PolicyEditor from "../components/PolicyEditor";
import { usePolicyStore } from "../lib/policyStore";

export default function PolicyEditorPage() {
  const policyManager = usePolicyStore();
  const policy = policyManager.getByKey()
  return (
    <React.Fragment>
      <>
        <Button
          className="me-1"
          onClick={() => policyManager.save(policy)} >Save</Button>

        {/* <Button
          className="me-1"
          variant="secondary"
          onClick={() => policyManager.exportPolicy(policy)}>Export</Button> */}

      </>

      <PolicyEditor value={policy} onChange={policy => policyManager.saveDraft(policy)} />
      <hr />

      {
        !!policy.registration && <React.Fragment>
          <h3>Registration</h3>
          <pre>{policy.registration.policyId}</pre>
        </React.Fragment>
      }

      {
        !!policy.registration && <React.Fragment>
          <h3>Addresses</h3>
          <AddressList policy={policy} />
        </React.Fragment>
      }
    </React.Fragment>)
}

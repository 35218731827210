import React from 'react'
import { Alert, Button, Col, Form, Row } from './ui'
import { Policy, EditorProps, PolicyTemplate, ValidationContext } from "../lib/policy"
import { getPolicyTemplate, getPolicyTemplates } from '../lib/policyStore'
import useRegisterPolicy from '../hooks/useRegisterPolicy'

export default function PolicyEditor({ value, onChange }: {
  value: Policy
  onChange: (value: Policy) => void
}) {
  const [template, setTemplate] = React.useState(getPolicyTemplate(value.templateId))
  const registerPolicy = useRegisterPolicy({ value, onChange })
  const validation = new ValidationContext()
  template.validate(value, validation)

  return (<React.Fragment>
    <Row>
      {/* 
      Name
      */}
      <Form.Group className="mb-3" as={Col}>
        <Form.Label>Name</Form.Label>
        <Form.Control id="name" type="text"
          value={value.name}
          onChange={e => onChange({ ...value, name: e.target.value, registration: undefined })} />
        <Form.Control.Feedback type="invalid">Invalid name</Form.Control.Feedback>
      </Form.Group>

      {/* 
      Template 
      */}
      <Form.Group className="mb-3" as={Col}>
        <Form.Label>Template</Form.Label>
        <PolicyTemplatePicker value={template} onChange={t => {
          onChange(t.create({ id: value.id, name: value.name, templateId: t.id, publicKeys: [] }))
          setTemplate(t)
        }} />
      </Form.Group>
    </Row>
    <hr />

    {React.createElement<EditorProps<Policy>>(template.editor, { value, onChange })}

    <hr />

    <h6>Policy Descriptor</h6>

    <pre>
      {template.createWalletPolicy(value).descriptorTemplate}
    </pre>

    {validation.isInvalid && <>
      <Alert>
        <p>The policy is invalid</p>
        <pre>{validation.errors.map((e, index) => <span key={index}>{e.message + '\n'}</span>)}</pre>
      </Alert>
    </>
    }

    <Button
      className="me-1"
      disabled={registerPolicy.isPending || validation.isInvalid}
      onClick={registerPolicy.run}>Register Now</Button>

  </React.Fragment >)
}

function PolicyTemplatePicker({ value, onChange }: {
  value: PolicyTemplate<Policy>
  onChange: (value: PolicyTemplate<Policy>) => void
}) {
  const templates = getPolicyTemplates()

  return (
    <Form.Select aria-label="Default select example"
      value={value.id}
      onChange={e => onChange(templates.find(t => t.id === e.target.value)!)}>
      {templates.map(template => <option key={template.id} value={template.id}>{template.name}</option>)}
    </Form.Select>)
}

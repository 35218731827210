import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import { Nav, Navbar } from '../components/ui'

export default function MainPage() {
  return (
    <React.Fragment>

      <Navbar bg="dark" expand="lg" className="p-2">
        <Navbar.Brand href="/" className="text-primary">Vault</Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link as={NavLink} to="/policies">Policies</Nav.Link>
          <Nav.Link as={NavLink} to="/keys">Keys</Nav.Link>
        </Nav>
      </Navbar>

      <Outlet />

    </React.Fragment>)
}


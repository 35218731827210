import React from 'react';
import { EditorProps, isValidDerivationPath, isValidMasterFingerprint, isValidPublicKey, PublicKey } from '../lib/policy';
import { Col, Form, Row } from './ui';

/**
 * PublicKeyForm
 */
export default function PublicKeyForm({ value, onChange }: EditorProps<PublicKey>) {
  return (<React.Fragment>
    <Row>
      <Form.Group as={Col} className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control id="name" type="text"
          value={value.name ?? ''}
          onChange={e => onChange({ ...value, name: e.target.value == '' ? undefined : e.target.value })} />
      </Form.Group>

      <Form.Group as={Col} className="mb-3">
        <Form.Label>Master Fingerprint</Form.Label>
        <Form.Control id="masterFingerprint" type="text"
          isInvalid={!isValidMasterFingerprint(value.masterFingerprint)}
          value={value.masterFingerprint}
          onChange={e => {
            console.log('fin', { ...value, masterFingerprint: e.target.value });
            onChange({ ...value, masterFingerprint: e.target.value });
          }} />
        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col} className="mb-3">
        <Form.Label>Derivation Path</Form.Label>
        <Form.Control id="derivationPath" type="text"
          isInvalid={!isValidDerivationPath(value.derivationPath)}
          value={value.derivationPath}
          onChange={e => onChange({ ...value, derivationPath: e.target.value, xpub: '' })} />
        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
      </Form.Group>
    </Row>

    <Row>
      <Form.Group as={Col} className="mb-3">
        <Form.Label>Extended Public Key</Form.Label>
        <Form.Control id="pubKey" type="text"
          isInvalid={!isValidPublicKey(value)}
          value={value.xpub}
          onChange={e => onChange({ ...value, xpub: e.target.value })} />
        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
      </Form.Group>
    </Row>
  </React.Fragment>);
}

import React from 'react'
import useAsync from '../hooks/useAsync'
import { kEmptyPublicKey } from '../lib/constants'
import { usePublicKeyStore } from '../lib/publicKeyStore'
import { useLedger } from '../lib/ledger'
import { EditorProps, isValidPublicKey, PublicKey } from '../lib/policy'
import PublicKeyForm from './PublicKeyForm'
import PublicKeyView from './PublicKeyView'
import { Button, Col, Container, Form } from './ui'
import { GroupEvent, GroupEventType, useVault } from '../lib/vault'

type PublicKeyEditorFactory = (props: EditorProps<PublicKey>) => JSX.Element

const kEditors: ReadonlyArray<{ label: string, factory: PublicKeyEditorFactory }> = [
  { label: 'From store', factory: PublicKeyStorePicker },
  { label: 'From device', factory: DevicePublicKeyControl },
  { label: 'From cosigner', factory: SharedPublicKeyControl },
  { label: 'Manual Entry', factory: PublicKeyForm },
]

/**
 * PublicKeyControl
 */
export default function PublicKeyControl({ value, onChange }: EditorProps<PublicKey>) {
  const [editorIndex, setEditorIndex] = React.useState(-1)
  const isValid = isValidPublicKey(value)
  const clearButton = <Button
    className=""
    variant="secondary"
    onClick={() => {
      setEditorIndex(-1)
      onChange({ ...value, ...kEmptyPublicKey })
    }}>Clear</Button>
  const saveKeyButton = useSavePublicButton({ value })

  const editor = isValid ? <PublicKeyView value={value} /> :
    editorIndex >= 0 ? React.createElement(kEditors[editorIndex].factory, { value, onChange }) :
      null

  return (<React.Fragment>

    <Container className="p-3" style={{ height: '300px' }}>

      <NameTextbox value={value} onChange={onChange} />

      {!isValid && editorIndex < 0 && kEditors.map((editor, index) =>
        <Button
          key={index}
          className="btn-sq-lg me-3"
          onClick={() => setEditorIndex(index)}>{editor.label}</Button>)}


      {editor}

      {(isValid || editorIndex >= 0) && clearButton}
      {(isValid || editorIndex >= 0) && <Button {...saveKeyButton} >Save</Button>}

    </Container>

  </React.Fragment>)
}

function useSavePublicButton({ value }: { value: PublicKey }) {
  const store = usePublicKeyStore()
  const hidden = !!store.find(value)
  const onClick = () => store.save(value)
  return { hidden, onClick }
}

function SharedPublicKeyControl({ value, onChange }: EditorProps<PublicKey>) {
  const share = usePublicKeyShare({ value, onChange })

  return <>
    <p>
      Copy the link and share it with the co-signer.
      The form will update automatically when the co-signer respond.
    </p>

    <Button variant="secondary" className="me-1" onClick={() => copy(share.link)} >
      Copy link
    </Button>

    {/* {share.events.map((e, index) => <div key={index}>{e.type}</div>)} */}

  </>
}

function copy(content: string) {
  navigator.clipboard.writeText(content)
    .then(() => { console.log('copied', content) })
    .catch(e => alert('could not copy: ' + e))
}

function NameTextbox({ value, onChange }: EditorProps<PublicKey>) {
  return <Form.Group as={Col} className="mb-3">
    <Form.Label>Name</Form.Label>
    <Form.Control id="name" type="text"
      value={value.name ?? ''}
      onChange={e => onChange({ ...value, name: e.target.value })} />
  </Form.Group>
}

export function DevicePublicKeyControl({ value, onChange }: EditorProps<PublicKey>) {
  const ledger = useLedger()
  const getKey = useAsync(async () => {
    const publicKey = await ledger.getPublicKey({ derivationPath: value.derivationPath, display: false })
    onChange({ ...value, ...publicKey })
  }, [])

  return <>
    <Button
      className="me-1"
      variant="primary"
      disabled={getKey.isPending}
      onClick={getKey.run}>Scan Device...</Button>
  </>
}

export function PublicKeyStorePicker({ value, onChange }: EditorProps<PublicKey>) {
  const store = usePublicKeyStore()

  return <>
    <Form.Select
      className="me-1"
      value={value.id}
      onChange={e => onChange(store.findByKey(e.target.value) ?? kEmptyPublicKey)}>
      {store.items.filter(isValidPublicKey).map(k => <option key={k.id} value={k.id}>{k.name}</option>)}
    </Form.Select>
  </>
}

function usePublicKeyShare({ value, onChange }: EditorProps<PublicKey>) {
  const [groupEvents, setGroupEvents] = React.useState<GroupEvent[]>([])
  const vault = useVault()

  React.useEffect(() => {
    setGroupEvents([])
    console.log('join')

    const subscriptionPromise = vault.joinGroup(value.id, { name: 'requester' }, e => {
      console.log('Event received', e)
      setGroupEvents(groupEvents => [e, ...groupEvents])
      if (e.type === GroupEventType.PublicKeyShared) {
        if (e.payload.publicKey.id !== value.id) {
          console.error('Unknown key')
          return
        }

        // Merge the 2 to keep the name and id
        onChange(e.payload.publicKey)
      }
    })

    return () => {
      subscriptionPromise.then(subscription => subscription.leave())
    }
  }, [value.id])

  return {
    link: getSharedKeyLink(value.id, value.name!),
    error: vault.error,
    events: groupEvents,
  }
}

function getSharedKeyLink(id: string, name: string): string {
  return `${location.origin}/share/key/${id}?name=${encodeURIComponent(name)}`
}

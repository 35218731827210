import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { updateArray } from '../components/update'
import useLocalStorage from '../hooks/useLocalStorage'
import { useDialog } from './dialog'

export type StoreProps<T> = {
  name: string
  itemKeyProvider: StoreItemKeyProvider<T>
  itemFactory: StoreItemFactory<T>
  itemComparer: (x: T, y: T) => number
}

export type StoreItemFactory<T> = () => T

export type StoreItemKeyProvider<T> = (item: T) => string

export function makeStore<T>(store: StoreProps<T>) {
  const context = React.createContext<Store<T>>(null!)

  const useStore = () => {
    const store = React.useContext<Store<T>>(context)
    if (store === null) { throw new Error('Context not set') }
    return store
  }

  const provider = ({ children }: React.PropsWithChildren<unknown>) => {
    const state = useStoreState<T>(store)
    const storeManager = React.useMemo(() => new Store<T>(state, store), [state.items, state.draft])
    return <context.Provider value={storeManager}>{children}</context.Provider>
  }

  return { useStore, provider }
}

function useStoreState<T>(store: StoreProps<T>) {
  return {
    dialog: useDialog(),
    navigate: useNavigate(),
    draft: useLocalStorage<T | undefined>(store.name + '.draft', undefined),
    items: useLocalStorage<ReadonlyArray<T>>(store.name, []),
  }
}

export class Store<T> {
  constructor(private _state: ReturnType<typeof useStoreState<T>>, private _props: StoreProps<T>) {
  }

  get items(): ReadonlyArray<T> { return this._state.items[0] }
  private get draft(): Readonly<T> | undefined { return this._state.draft[0] }

  /**
   * Get item by key
   * @param key item to use. If a key is not specified, it will try to get it
   * from the route params.
   * @returns the item or throws is not found
   */
  getByKey(key?: string): T {
    key ??= useRouteKeyParam()

    if (this.draft && this._props.itemKeyProvider(this.draft) === key) {
      return this.draft
    }

    const item = this.findByKey(key)
    if (!item)
      throw new Error(`Not found ${key}`)
    return item
  }

  open(item: T): void {
    this._state.navigate(`${this.getItemLink(item)}`)
  }

  new() {
    const item = this._props.itemFactory()
    this.setDraft(item)
    this._state.navigate(`${this.getItemLink(item)}/edit`)
  }

  edit() {
    const item = this.getByKey()
    this._state.navigate(`${this.getItemLink(item)}/edit`)
  }

  findByKey(key: ReturnType<StoreItemKeyProvider<T>>): T | undefined {
    return this.items.find(item => this._props.itemKeyProvider(item) === key)
  }

  find(item: T): T | undefined {
    return this.items.find(k => this._props.itemComparer(k, item) === 0)
  }

  close() {
    this._state.navigate(`${this.getLink()}`)
    this.setDraft(undefined)
  }

  saveDraft(item: T) {
    this.setDraft(item)
  }

  save(item: T) {
    const key = this._props.itemKeyProvider(item)
    const index = this.items.findIndex(item => this._props.itemKeyProvider(item) === key)
    this.setItems(updateArray<T>(this.items, index, item))
  }

  private setDraft(value: T | undefined) {
    this._state.draft[1](value)
  }

  private setItems(value: ReadonlyArray<T>) {
    this._state.items[1](value)
  }

  private getLink(): string { return `/${this._props.name}` }

  private getItemLink(item: T): string { return `/${this._props.name}/${this._props.itemKeyProvider(item)}` }
}

function useRouteKeyParam(): string {
  const params = useParams<{ key: string }>()
  if (params.key == null)
    throw new Error('key not found')
  return params.key
}

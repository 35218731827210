import React from 'react'
import TransportWebHID from '@ledgerhq/hw-transport-webhid'
import { AppClient, PsbtV2 } from '../ledger-bitcoin'
import { Dialog, useDialog } from './dialog'
import { Policy, PolicyRegistration, PublicKey } from './policy'
import { getPolicyTemplate } from './policyStore'
import * as dialogs from './ledger-dialogs'

const Context = React.createContext<Ledger>(null!)

export type LedgerState = {
    readonly connected: boolean
    getKeyInfo: typeof Ledger.prototype.getPublicKey
}

export type HIDDevice = {
    readonly productName: string
    readonly opened: boolean
}

export function useLedger(): Ledger {
    const context = React.useContext(Context)
    if (context === null) { throw new Error('useLedger must be used within a LedgerProvider') }
    return context
}

export function LedgerProvider({ children }: React.PropsWithChildren<unknown>) {
    const dialog = useDialog()
    const ledger = React.useMemo(() => new Ledger(dialog), [dialog])
    return (<Context.Provider value={ledger}>{children}</Context.Provider>)
}

// https://developers.ledger.com/docs/transport/web-hid-usb/
// https://github.com/LedgerHQ/app-bitcoin-new
// import Transport from '@ledgerhq/hw-transport-node-hid';
export class Ledger {
    private _client: AppClient | null = null

    constructor(private _dialog: Dialog) {
    }

    async getPublicKey({ derivationPath, display }: { derivationPath: string; display: boolean }): Promise<PublicKey> {
        const client = await this.getClient()

        if (display) {
            this._dialog.open(dialogs.confirmPublicKey(derivationPath))
        }

        const fingerprint = await client.getMasterFingerprint()
        const xpub = await client.getExtendedPubkey(`m/${derivationPath}`, display)
        this._dialog.close()
        return { id: '', name: '', masterFingerprint: fingerprint, xpub, derivationPath: derivationPath }
    }

    async registerPolicy({ policy }: { policy: Policy }): Promise<PolicyRegistration> {
        const template = getPolicyTemplate(policy.templateId)
        const walletPolicy = template.createWalletPolicy(policy)
        this._dialog.open(dialogs.registerPolicy(walletPolicy))

        try {
            const client = await this.getClient()
            console.debug('ledger.register', policy)
            const [policyId, policyHmac] = await client.registerWallet(walletPolicy)
            const registration: PolicyRegistration = {
                name: policy.name,
                policyId: policyId.toString('hex'),
                policyHmac: policyHmac.toString('hex'),
                descriptorTemplate: walletPolicy.descriptorTemplate,
                keys: walletPolicy.keys,
            }
            console.debug('ledger.registered', registration)
            this._dialog.close()
            return registration
        } catch (e: any) {
            console.error(e)
            this._dialog.openError(e)
            throw e
        }
    }

    async getAddress(address: Address, display: boolean): Promise<Address> {
        const client = await this.getClient()
        const policyHmac = Buffer.from(address.policy.registration!.policyHmac, 'hex')
        const template = getPolicyTemplate(address.policy.templateId)
        const walletPolicy = template.createWalletPolicy(address.policy)
        if (display) {
            this._dialog.open({ title: 'Export Address', body: 'Check your device ' + address.address })
        }
        const addressString = await client.getWalletAddress(walletPolicy, policyHmac, address.isChange ? 1 : 0, address.index, display)
        this._dialog.close()
        return { ...address, address: addressString }
    }

    async signMessage(message: string, derivationPath: string): Promise<string> {
        const client = await this.getClient()
        this._dialog.open(dialogs.signMessage(derivationPath, message))
        const signature = await client.signMessage(Buffer.from(message, 'utf8'), derivationPath)
        this._dialog.close()
        return signature
    }

    async sign({ rawPsbtBase64, policy }: SignRequest): Promise<any> {
        try {

            const template = getPolicyTemplate(policy.templateId)
            const walletPolicy = template.createWalletPolicy(policy)

            const psbt = new PsbtV2()
            psbt.deserialize(Buffer.from(rawPsbtBase64, 'base64'))
            const client = await this.getClient()
            console.log(walletPolicy, psbt)

            await client.signPsbt(psbt, walletPolicy, Buffer.from(policy.registration?.policyHmac!, 'hex'))
        } catch (e) {
            console.log(e)
        }
        return null
    }

    private async getClient(): Promise<AppClient> {
        if (!this._client) {
            let transport = await TransportWebHID.openConnected()
            if (!transport) {
                console.log('ledger.request')
                transport = await await TransportWebHID.request()
            }
            if (!transport) {
                throw new Error("TODO")
            }

            console.debug('ledger.opened', transport)
            this._client = new AppClient(transport)
        }

        return this._client
    }
}

export const AutoCloseNotice = () => <small>This dialog will close automatically when your confirmed on the device</small>

export type SignRequest = {
    readonly policy: Policy
    /**
     * a base64-encoded psbt file to sign
     */
    readonly rawPsbtBase64: string
}

export type Address = {
    readonly address: string
    readonly policy: Policy
    readonly index: number
    readonly isChange: boolean
}

export function createPsbt() {
    const t = new PsbtV2()
    const txId = Buffer.from('afaaf61d9be408eb98cbbbe341bc5388192fb2c5e004c13d05a3bc5b92091d7c', 'hex')

    t.setGlobalInputCount(1)
    t.setGlobalOutputCount(0)

    const inputIndex = 0
    const inputOutputIndex = 0
    t.setInputPreviousTxId(inputIndex, txId)
    t.setInputOutputIndex(inputIndex, inputOutputIndex)

    const outputIndex = 0
    const amount = 10000
    t.setOutputAmount(outputIndex, amount)

    const buffer = t.serialize().toString('hex')
    console.log(buffer)
    const t2 = new PsbtV2()
    console.log(t,)

    t2.deserialize(Buffer.from(buffer, 'hex'))
    // t2.deserialize(Buffer.from(t.serialize().toString('base64'), 'base64'))
    return t
}

import React from 'react'
import { isIos } from '../components/ui'
const version = '0.1'

/**
 * PwaState
 */
export type PwaState = {
  hasUpdate: boolean
  version: string
  registration: ServiceWorkerRegistration | null
}

const initialPwaState: PwaState = Object.freeze({ hasUpdate: false, version, registration: null })
const Context = React.createContext<PwaState | null>(null)

/**
 * shouldDisplayInstallPopup
 */
export function shouldDisplayInstallPopup() {
  return isIos
}

export function PwaProvider({ children }: React.PropsWithChildren<unknown>) {
  const disabled = true//process.env.VAULT_PWA_ENABLE === 'false'
  const [state, setState] = React.useState<PwaState>(initialPwaState)

  React.useEffect(() => {
    if (disabled)
      console.log('pwa.disabled')
    else
      registerAsync()
  }, [])

  async function registerAsync() {
    const registration = await navigator.serviceWorker.register(new URL('./pwa-worker.ts', import.meta.url), { type: 'module' })
    console.debug('pwa.registered', registration.scope)
    setState(s => ({ ...s, registration }))
  }

  return (<Context.Provider value={state} > {children} </Context.Provider>)
}

export function usePwa(): Readonly<PwaState> {
  const context = React.useContext(Context)
  if (!context) {
    throw new Error('Provider not found')
  }
  return context
}

import React from 'react'

import { HubConnectionBuilder, HttpTransportType, LogLevel, HubConnection, HubConnectionState } from '@microsoft/signalr'
import useLocalStorage from '../hooks/useLocalStorage'
import { ripemd160 } from './crypto'
import { useSearchParams } from 'react-router-dom'
import { PublicKey } from './policy'

const Context = React.createContext<Vault>(null!)
const SERVICE_URL = location.host.endsWith('1234') ? 'http://localhost:5000/ws' : '/ws'

export function useVault(): Vault {
  const context = React.useContext(Context)
  if (context === null) { throw new Error('Context not set') }
  return context
}

export function VaultProvider({ children }: React.PropsWithChildren<unknown>) {
  const [searchParams, _] = useSearchParams()
  const clientId = searchParams.get('cid') ?? useLocalStorage<string>('clientId', () => crypto.randomUUID())[0];
  const value = React.useMemo(() => new Vault(SERVICE_URL, clientId), [])
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export type ServiceInfo = {}

export type Subscription = {
  groupId: string
  leave: () => void
  options: JoinGroupOptions
  handler: (e: GroupEvent) => void
}

export enum GroupEventType {
  Joined = "Joined",
  Left = "Left",
  PublicKeyShared = 'PublicKeyShared',
}

export type JoinedGroupEvent = {
  type: GroupEventType.Joined
  payload: JoinGroupOptions
}

export type LeftGroupEvent = {
  type: GroupEventType.Left
}

export type PublicKeySharedEvent = {
  type: GroupEventType.PublicKeyShared
  payload: SharedPublicKey
}

export type GroupEvent = JoinedGroupEvent | PublicKeySharedEvent

export type JoinGroupOptions = {
  name: string
}

export type SubscriptionHandler<T> = (e: T) => void
export class Vault {
  private readonly _url: string
  private readonly _subscriptions: Map<string, Subscription> = new Map<string, Subscription>()
  private readonly _clientId: string
  private _connection: HubConnection | null = null
  private _connectionPromise: Promise<void> | null = null
  private _error: Error | null = null

  constructor(url: string, clientId: string) {
    this._url = url
    this._clientId = clientId
  }

  get isConnected() {
    return this._connection?.state === HubConnectionState.Connected
  }

  get error(): Error | null {
    return this._error
  }

  getSharedKeyId(keyId: string): string {
    const sharedKeyId = `${this._clientId}/${keyId}`
    return ripemd160(Buffer.from(sharedKeyId, 'utf8')).toString('hex')
  }

  async getServiceInfo(): Promise<ServiceInfo> {
    const connection = await this.getConnection()
    const result = connection.invoke('getServiceInfo')
    return result;
  }

  async joinGroup(groupId: string, options: JoinGroupOptions, handler: SubscriptionHandler<GroupEvent>): Promise<Subscription> {
    console.debug('vault.joinGroup', groupId);
    const connection = await this.getConnection()
    await connection.invoke('JoinGroup', groupId, options)

    const subscription: Subscription = {
      groupId,
      options,
      leave: () => this.leaveGroup(groupId),
      handler,
    }
    this._subscriptions.set(groupId, subscription)
    return subscription;

  }

  private async rejoinGroups(): Promise<void> {
    console.debug('vault.rejoinGroups', this._subscriptions.size)
    for (const groupId of this._subscriptions.keys()) {
      const oldSubscription = this._subscriptions.get(groupId)!
      await this.joinGroup(groupId, oldSubscription.options, oldSubscription.handler)
    }
  }

  async leaveGroup(groupId: string): Promise<void> {
    console.debug('vault.leaveGroup', groupId)
    const connection = await this.getConnection()
    this._subscriptions.delete(groupId);
    await connection.invoke('LeaveGroup', groupId)
  }

  async notifyGroup(groupId: string, event: GroupEvent): Promise<void> {
    console.debug('vault.notifyGroup', groupId, event)
    const connection = await this.getConnection()
    await connection.invoke('NotifyGroup', groupId, event)
  }

  private async getConnection(): Promise<HubConnection> {
    if (this._connectionPromise != null)
      await this._connectionPromise

    if (this._connection != null)
      return this._connection

    try {
      const connection = this._connection = this.createConnection()
      this._connectionPromise = connection.start()
      await this._connectionPromise
      console.log('vault.connected')
      this._error = null;
      return connection;
    } catch (error: any) {
      this._error = error
      throw error
    }
    finally {
      this._connectionPromise = null
    }
  }

  private createConnection() {
    const connection = new HubConnectionBuilder()
      .withUrl(this._url, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        withCredentials: false,
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: () => {
          return 1000
        }
      })
      .configureLogging(LogLevel.Error)
      .build()

    connection.onreconnecting(error => {
      console.log('vault.onreconnecting', error)
    })
    connection.onreconnected(() => {
      console.log('vault.onreconnected')
      this.rejoinGroups()
    })
    connection.onclose((error?: Error) => {
      console.log('vault.close', error)
    })
    connection.on('event', e => {
      console.debug('vault.event', e)
      for (const subscription of this._subscriptions.values()) {
        subscription.handler(e)
      }
      // const { subscriptionId } = e
      // const subscription = this._subscriptions.find(s => s.subscriptionId === subscriptionId)
      // if (subscription)
      //   subscription.handler(e.event)

      // else
      //   console.warn('Event ignored')
    })
    return connection
  }
}


export type SharedPublicKey = {
  readonly publicKey: Readonly<PublicKey>

  /**
   * Bitcoin message signature of 'keyId||publicKey'
   */
   readonly signature?: string
}
import React from 'react'
import { WalletPolicy } from '../../ledger-bitcoin';
import { formatPublicKey, isValidPublicKey, Policy, PolicyTemplate, PublicKey, Timelock } from "../policy";
import PublicKeyForm from '../../components/PublicKeyForm';
import { kEmptyPublicKey } from '../constants';
import { Form } from '../../components/ui';

export interface MultisigTimelockPolicy extends Policy {
  readonly userPublicKey: PublicKey;
  readonly servicePublicKey: PublicKey;
  readonly serviceTimelock: Timelock
}

function Editor({ value, onChange }: {
  value: MultisigTimelockPolicy;
  onChange: (value: MultisigTimelockPolicy) => void;
}) {
  return (<React.Fragment>
    <h2>User Public Key</h2>
    <PublicKeyForm
      value={value.userPublicKey}
      onChange={userPublicKey => onChange({ ...value, userPublicKey })} />

    <h2>Service Public Key</h2>

    <PublicKeyForm
      value={value.servicePublicKey}
      onChange={servicePublicKey => onChange({ ...value, servicePublicKey })} />

    <Form.Group className="mb-3">
      <Form.Label>Service time lock</Form.Label>
      <Form.Control
        value={value.serviceTimelock}
        onChange={e => onChange({ ...value, serviceTimelock: e.target.value })} />
      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
    </Form.Group>

  </React.Fragment>);
}

function validate(value: any): value is MultisigTimelockPolicy {
  return typeof value === 'object' &&
    isValidPublicKey((value as MultisigTimelockPolicy).servicePublicKey) &&
    isValidPublicKey((value as MultisigTimelockPolicy).userPublicKey) &&
    true
}

const template: PolicyTemplate<MultisigTimelockPolicy> = {
  id: 'multisig-timelock',
  name: 'User or Recovery Service with timelock',
  create: policy => ({
    ...policy,
    name: 'Cold Storage',
    serviceTimelock: 'P30D',
    servicePublicKey: kEmptyPublicKey,
    userPublicKey: kEmptyPublicKey,
  }),
  createWalletPolicy: policy => new WalletPolicy(
    policy.name,
    "and(pk(@0),or(99@pk(@1),older(1008)))",
    [
      formatPublicKey(policy.userPublicKey),
      formatPublicKey(policy.servicePublicKey),
    ]
  ),
  editor: Editor,
  validate: validate,
}

export default template;
import { useParams, useSearchParams } from "react-router-dom"
import React, { useEffect } from 'react'
import { Button, Col, Container, Form } from '../components/ui'
import { useLedger } from '../lib/ledger'
import { GroupEventType, useVault } from '../lib/vault'
import { kDefaultDerivationPath } from '../lib/constants'
import useAsync from "../hooks/useAsync"
import { isValidDerivationPath } from "../lib/policy"

export default function SharePublicKeyPage() {
  const params = useParams<{ id: string }>()
  const [searchParams, _] = useSearchParams()
  const name = searchParams.get('name') ?? 'anonymous'
  // const displayPubKey = searchParams.get('display') === 'false' ? false : true
  const [derivationPath, _setDerivationPath] = React.useState<string>(searchParams.get('derivationPath') ?? kDefaultDerivationPath)
  const [isShared, setIsShared] = React.useState(false)
  const vault = useVault()
  const ledger = useLedger()
  const { id } = params
  if (id == '' || id == null) {
    return <div>Missing id</div>
  }

  const share = useAsync(async () => {
    const publicKey1 = await ledger.getPublicKey({ derivationPath, display: false })
    const publicKey = { ...publicKey1, id, name }
    // const signature = await ledger.signMessage(sharedPublicKey.keyId + sharedPublicKey.publicKey, derivationPath)
    const signature = 'ab'
    await vault.notifyGroup(id, { type: GroupEventType.PublicKeyShared, payload: { publicKey, signature } })
    setIsShared(true)
  })

  if (isShared)
    return <ThankYouNotice />

  return <Container>
    <Container>
      <h3>Share Multi-sig Public Key</h3>
      <h3>{name}</h3>
      <ul>
        <li>Connect your device</li>
        <li>Open Bitcoin application</li>
        <li>Click the 'Share' button</li>
      </ul>
      <div></div>
      {/* <DerivationPathControl value={derivationPath} onChange={setDerivationPath} /> */}
      {/* {group.joined && <p>Ready</p>} */}

      <Button
        disabled={share.isPending}
        onClick={share.run}>Share</Button>

    </Container>
    <hr />

    <p className="fs-6 fst-italic">
      *You are asked to share your public key and participate to Multi-Sig policy.
      This public key is different from the one used for your regular Bitcoin accounts.
      It is perfectly safe to share it as it should never be used to receive Bitcoin on it.
      In any event, sharing a public will never allow to spend any funds.
      Read more on <a href="https://en.bitcoin.it/wiki/BIP_0048" target="_">BIP48</a>
    </p>

  </Container>
}

export function DerivationPathControl({ value, onChange }: {
  value: string
  onChange: (value: string) => void
}) {
  return <Form.Group as={Col} className="mb-3">
    <Form.Label>Derivation Path</Form.Label>
    <Form.Control id="derivationPath" type="text"
      isInvalid={!isValidDerivationPath(value)}
      value={value}
      onChange={e => onChange(e.target.value)} />
    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
  </Form.Group>
}

function ThankYouNotice() {
  return <Container>
    <h1>Thank you!</h1>
    <p>You can close this page</p>
  </Container>
}

export function useVaultGroup(groupId: string, options: { name: string }) {
  const vault = useVault()
  const [joined, setJoined] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>(undefined)

  useEffect(() => {
    const promise = vault.joinGroup(groupId, options, e => {
      console.log('Received GroupEvent', e)
    }).then(group => {
      setJoined(true)
      return group
    }).catch(error => {
      setError(error)
      throw error
    })

    return () => {
      promise.then(group => group.leave())
    }
  }, [])

  return { joined, error }
}